<template lang="pug">
  form-wrapper(:validator="$v.user")
    app-panel
      app-header User details

      app-text-field(class="w-1/2", label="Name", name="name", v-model="user.name", @input="update('name', $event)")
      app-text-field(class="w-1/3", label="Email", name="email", v-model="user.email", @input="update('email', $event)")

      app-dropdown-field.flex-1(class="w-1/4", v-if="roles", name="role", label="Assign Role", :value="user.role", :options="rolePossibleValues", value-attr="value", @input="update('role', $event)" track-by="value")

      app-dropdown-field.flex-1(class="w-1/4", v-if="dateFormats", name="dateFormat", label="Date Format", :value="user.dateFormat", :options="dateFormatPossibleValues", value-attr="value", @input="update('dateFormat', $event)")

      app-dropdown-field.flex-1(class="w-1/4", v-if="timezones", name="timezone", label="Timezone", :value="user.timezone", :options="timezonePossibleValues", value-attr="value", @input="update('timezone', $event)")

      .mb-8
        app-button(primary, @click.prevent="submit()") {{ submitLabel }}
        app-button.ml-8(@click.prevent="$emit('cancel')") Cancel

</template>

<script>
import RolesQuery from "@/graphql/queries/Roles.gql";
import DateFormatsQuery from "@/graphql/queries/DateFormats.gql";
import TimezonesQuery from "@/graphql/queries/Timezones.gql";
import { required } from "vuelidate/lib/validators";
import clone from "ramda/src/clone";
export default {
  apollo: {
    roles: {
      query: RolesQuery,
      variables() {
        return { company: { id: this.$route.params.id } };
      },
      update(data) {
        return data.roles;
      },
      fetchPolicy: "no-cache",
    },
    dateFormats: DateFormatsQuery,
    timezones: TimezonesQuery,
  },
  data() {
    return {
      user: clone(this.value),
      isLoading: false
    };
  },
  methods: {
    submit() {
      if(!this.isLoading) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.isLoading = true;

        this.$emit("submit");
      }
    },
    update(key, value) {
      this.user[key] = value;
      this.$emit("input", { ...this.user, [key]: value });
    },
  },
  computed: {
    submitLabel() {
      if (this.newUser) {
        return "Create";
      }
      return "Update";
    },
    rolePossibleValues() {
      if (this.roles) {
        return this.roles.map( function(role) {
          return { label: role.name, value: role.id };
        })
      } else {
        return [];
      }
    },
    dateFormatPossibleValues() {
      const { possibleValues = [] } = this.dateFormats;

      if (possibleValues) {
        return possibleValues.map(df => ({ label: df.value, value: df.value }));
      }

      return [];
    },
    timezonePossibleValues() {
      const { possibleValues = [] } = this.timezones;

      if (possibleValues) {
        return possibleValues
          .map(tz => ({ label: tz.value, value: tz.value }))
          .sort((optA, optB) => optA.label.localeCompare(optB.label));
      }

      return [];
    },
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
      },
      role: {
        required,
      },
      dateFormat: {
        required,
      },
      timezone: {
        required,
      },
    },
  },
  props: {
    newUser: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

